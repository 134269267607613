import LineGradient from "../components/LineGradient";
import { ethers, BigNumber } from "ethers";
import { motion } from "framer-motion";
import { useState } from "react";
import infinitilearningdao from "../Infinitilearningdao.json"
const infinitilearningdaoAddress = "0xa16654d69bf71a6836effded25f0d98eb4e1bc96";

const Mint = ({accounts, setAccounts}) => {

  const [mintAmount, setMintAmount] = useState(1);

  const isConnected = Boolean(accounts[0]);

  async function connectAccount() {
      if (window.ethereum) {
          const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
          });
          setAccounts(accounts);
      }
  }

  async function handleMint() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        infinitilearningdaoAddress,
        infinitilearningdao.abi,
        signer
      );
      try {
        const response = await contract.mint(BigNumber.from(mintAmount), {
          value: ethers.utils.parseEther((0.02 * mintAmount).toString()),
        });
        console.log("response: ", response);
      } catch (err) {
        console.log("error: ", err);
      }
    }
  }











 
  return (
    <section id="mint" className="contact py-30">
      {/* HEADINGS */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="flex justify-end w-full"
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-emerald-500">Connect& </span>  Mint Your NFT
          </p>
          

          
          





          <div className="flex md:justify-end my-5">
            <LineGradient width="w-1/2" />
          </div>
        </div>
      </motion.div>

      {/* FORM & IMAGE */}
      <div className="md:flex md:justify-between gap-16 mt-5">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 flex justify-center"
        >
          <img src="../assets/giphy.gif" alt="contact" />
        </motion.div>


        {isConnected ? (
          <button
              className="p-5 bg-yellow font-semibold text-deep-blue mt-5 hover:bg-red hover:text-white transition duration-500">
              Yes! You Connected With Polygon 👍
            </button>
        ):(
          <button
              className="p-5 bg-yellow font-semibold text-deep-blue mt-5 hover:bg-red hover:text-white transition duration-500"
              onClick={connectAccount}
            >
              Please Connect Metamask
            </button>
        )}
          

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 mt-10 md:mt-0"
        >
          {isConnected ? (
            <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 mt-10 md:mt-0 justify-center items-center text-center"
        >
            <img src="../assets/background.gif" alt="contact" className="h-100" />
            <button
              className="p-10 bg-yellow font-semibold text-deep-blue mt-5 hover:bg-red hover:text-white transition duration-500 "
              onClick={handleMint}
            >
              Mint Now
            </button>
            
            </motion.div>
            
          ):(
            <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 mt-10 md:mt-0"
        >
            
            <img src="../assets/giphy.gif" alt="contact" />
</motion.div>
          )}
          
          
            
        </motion.div>

      </div>
    </section>
  );
};

export default Mint;
